   import React, { useEffect, useState } from "react"

   import DownloadApps from "../components/DownloadApps"
   import Layout from "../components/layout"
   import SEO from "../components/seo"
   import { Row, Col } from "antd";

const ComenzarPage = () => {
   const [article] = useState('');



   return (
      <Layout>
         <SEO
         title="Muvu - La app que te recompensa por hacer actividad física."
         description="Muvu es la app que te premia por hacer actividad física, gana puntos y canjealos por increibles premios."
         />
         <>
         <section className="get-started common-container">
            <Row justify="center">
               <Col lg={12}>
               <h1>Como comenzar a usar Muvu</h1>
               <div className="get-started__content" dangerouslySetInnerHTML={{ __html: article.body }}>
               </div>
               <div className="get-started__actions">
                  <h2>Descarga Muvu</h2>
                  <DownloadApps withoutLinks={true} />
               </div>
               </Col>
            </Row>
         </section>
         </>
      </Layout>
   );
}

   export default ComenzarPage